/* hero banner 02 */
.sticky-cta-button {
  position: fixed;
  left: 0;
  right: 0;
  top: 3.1rem;
  z-index: 1000;
  width: 100%;
  background: white;
  box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
}
.sticky-cta-button > div:first-child {
  color: black;
  width: 100%;
  display: flex;
  justify-content: center;
}

.sticky-cta-button > div:first-child p:first-child {
  color: black;
  white-space: nowrap;
  height: 100%;
  width: 220px;
  font-weight: 600;
  padding: 25px 0px 20px 0px;
  font-size: 20px;
  text-align: center;
}
