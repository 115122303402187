.paragraph-text-transformbrand {
  padding-top: 0.7rem;
  color: black;
  padding-bottom: 1.2rem;
  text-align: start;
  max-width: calc(100vw - 20vw);
}

@media screen and (min-width: 786px) {
  .paragraph-text-transformbrand {
    text-align: start;
  }
}
