.sticky-phone-button-wp {
  position: fixed;
  right: -2px;
  bottom: calc(100vh - 70vh);
  z-index: 1000;
  display: flex;
  width: 46px;
  padding: 14px 8px 10px 8px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.sticky-phone-button-wp > a:first-child {
  border-radius: 40px 0px 0px 40px;
  background: #fff;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow:
    5px 5px 15px rgba(0, 0, 0, 0.1),
    inset -5px -5px 15px rgba(255, 255, 255, 0.5);
}

.sticky-phone-button-wp > a > p {
  display: block;
  white-space: nowrap;
  padding-left: 5px;
  font-weight: 500;
}

.sticky-phone-button-wp > a:first-child {
  position: relative;
  /* Add position relative for absolute positioning of ::before content */
  padding-left: 20px;
  background: #fff;
  width: 70px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  transition: width 0.3s ease;
  /* Add transition for smooth effect */
}
