.heading {
  text-align: start;
}

@media screen and (max-width: 768px) {
  .heading {
    max-width: 95vw;
  }
}
.paragraph {
  font-weight: 300;
  padding-top: 15px;
  max-width: calc(100vw - 20vw);
  text-align: start;
}

@media screen and (max-width: 768px) {
  .paragraph {
    width: 100%;
  }
}

.collageChild,
.collageChild1,
.collageInner,
.collageItem,
.rectangleIcon {
  top: -0.5rem;
  left: -0.5rem;
  width: 50.5rem;
  height: 16.69rem;
  object-fit: cover;
}
.collageChild1,
.collageInner,
.collageItem,
.rectangleIcon {
  top: 16.63rem;
}
.collageChild1,
.collageInner,
.rectangleIcon {
  left: 23.44rem;
}
.collageChild1,
.rectangleIcon {
  top: -0.5rem;
}
.collageChild1 {
  left: 47.38rem;
  width: 21.25rem;
  height: 33.81rem;
}
.collage {
  display: flex;
}
.vectorIcon,
.viewAll {
  position: absolute;
  top: 0;
  left: 0;
}
.vectorIcon {
  height: 61.29%;
  width: 15.57%;
  top: 19.35%;
  right: 0;
  bottom: 19.35%;
  left: 105.43%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.ctaButton {
  position: absolute;

  height: 1.94rem;
  font-size: 1.63rem;
}
.portfolioSection {
  position: relative;
  border-radius: 2px;
  /* width: 100%; */
  /* height: 67.5rem; */
  overflow: hidden;
  background-repeat: no-repeat;
  background-position: top;
  text-align: center;
  font-size: 2.25rem;
  color: #fff;
  height: 100%;
  padding-top: 2rem;
  padding-bottom: 2rem;
}
